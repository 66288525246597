import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../common/AuthContext';
import { DadosContext } from '../../../common/DadosContext';

const MagaluCallbackChat = ({ code }) => {
  const { setaccessCodeMagaluMSG, setrefreshCodeMagaluMSG } = useAuth();
  const { saveToken } = useContext(DadosContext);
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthorizationCallback = async () => {
      if (!code) {
        console.error('Authorization code is missing');
        return;
      }

      const payload = new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: process.env.REACT_APP_MAGALUMSG_ID,
        client_secret: process.env.REACT_APP_MAGALUMSG_SECRET,
        code,
        redirect_uri: 'https://nerp.com.br/magalumsg'
      }).toString();
      

      try {
        const response = await axios.post('https://id.magalu.com/oauth/token', payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          }
        });
        console.log('Response:', response.data);

        

        // Salvar os tokens no contexto
        setaccessCodeMagaluMSG(response.data.access_token);
        setrefreshCodeMagaluMSG(response.data.refresh_token);
        saveToken('magalu-chat',response.data.access_token, response.data.refresh_token);
        const response2 = await axios.post('https://nerp.com.br/magalu/onboardingmsg', {
          accessToken: response.data.access_token,
          webhook: 'https://nerp.com.br/magalu/webhook'
        });

        
        console.log(response2)
        

        navigate('/integracao');
      } catch (error) {
        console.error('Error handling MercadoLivre authorization:', error.response ? error.response.data : error.message);
      } finally {
        
      }
    };

    handleAuthorizationCallback();
  }, [code, navigate, setaccessCodeMagaluMSG, setrefreshCodeMagaluMSG]);

  return null;
};

export default MagaluCallbackChat;

import React, { useContext, useState } from 'react';
import { DadosContext } from '../common/DadosContext';
import axios from 'axios';
import ProdutosList from './Produtos';

const SKUsList = () => {
    const { tokens, createProdutoMagalu } = useContext(DadosContext);
    const [skus, setSkus] = useState([]); // Estado inicial para SKUs únicos
    const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de busca
    const [filteredSKUs, setFilteredSKUs] = useState([]); // SKUs filtrados
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchAllSKUs = async () => {
        const token = tokens.find((t) => t.id === 'magalu')?.token;

        if (!token) {
            setError('Token não encontrado');
            setLoading(false);
            return;
        }

        let offset = 0;
        const limit = 100; // Tamanho da página
        const allSKUs = new Map(); // Garante SKUs únicos pelo ID ou Code

        try {
            setLoading(true);
            setError(null);

            while (true) {
                const response = await axios.post(
                    'https://nerp.com.br/magalu/skus',
                    {
                        accessToken: token,                       
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                );

                const newSKUs = response.data.skus || [];

                // Adiciona apenas SKUs únicos ao Map (baseado no code como chave)
                newSKUs.forEach((sku) => {
                    if (!allSKUs.has(sku.code)) {
                        allSKUs.set(sku.code, sku);
                    }
                });

                // Verifica se não há mais SKUs retornados e encerra o loop
                if (newSKUs.length === 0) break;

                offset += limit; // Incrementa o offset para a próxima página
            }

            // Atualiza o estado com SKUs únicos
            const uniqueSKUs = Array.from(allSKUs.values());
            setSkus(uniqueSKUs);
            setFilteredSKUs(uniqueSKUs); // Inicializa com todos os SKUs
        } catch (err) {
            console.error('Erro ao buscar SKUs:', err.message || err);
            setError('Erro ao buscar SKUs');
        } finally {
            setLoading(false);
        }
    };

    // Salva todos os SKUs no Firebase
    const saveAllToFirebase = async () => {
        try {
            setLoading(true);
            for (const sku of skus) {
                await createProdutoMagalu(sku); // Salva cada SKU no Firebase
            }
            alert('Todos os SKUs foram salvos com sucesso no Firebase!');
        } catch (error) {
            console.error('Erro ao salvar os SKUs no Firebase:', error);
            alert('Erro ao salvar os SKUs no Firebase.');
        } finally {
            setLoading(false);
        }
    };

    // Atualiza os SKUs filtrados conforme o termo de busca muda
    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = skus.filter((sku) => {
            const title = sku?.title?.pt_BR?.toLowerCase() || '';
            const code = sku?.code?.toLowerCase() || '';
            return title.includes(term) || code.includes(term);
        });
        setFilteredSKUs(filtered);
    };

    return (
        <div className="container mx-auto px-4 py-36">
            <h1 className="text-2xl font-bold mb-4">Lista de SKUs</h1>

            {/* Botão para buscar SKUs */}
            <div className="mb-4">
                <button
                    className="px-4 py-2 bg-green-500 text-white rounded mr-2"
                    onClick={fetchAllSKUs}
                >
                    Buscar SKUs
                </button>
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                    onClick={saveAllToFirebase}
                    disabled={skus.length === 0 || loading}
                >
                    Salvar Todos no Firebase
                </button>
            </div>

            <ProdutosList/>

            {/* Campo de busca */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Busque por nome ou código..."
                    className="w-full p-2 border rounded"
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>

            {loading ? (
                <p className="text-center text-gray-500">Carregando...</p>
            ) : error ? (
                <p className="text-center text-red-500">{error}</p>
            ) : filteredSKUs.length > 0 ? (
                <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {filteredSKUs.map((sku, index) => (
                        <li key={sku.code || index} className="border p-4 rounded shadow">
                            <h3 className="text-lg font-bold">{sku?.title?.pt_BR || 'Título não disponível'}</h3>
                            <p className="text-sm text-gray-600">Marca: {sku?.brand || 'Marca não especificada'}</p>
                            <p className="text-sm text-gray-500">Código: {sku?.status || 'Código indisponível'}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="text-center text-gray-500">Nenhum SKU encontrado.</p>
                
            )}
        </div>
    );
};

export default SKUsList;

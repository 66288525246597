import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { DadosContext } from '../../common/DadosContext';

const Conversations = () => {
  const { tokens } = useContext(DadosContext);
  const [conversations, setConversations] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10, offset: 0, total: 0 });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Filtros
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    fetchConversations();
  }, [pagination.offset, pagination.limit]);

  const fetchConversations = async () => {
    setLoading(true);
    setError(null);

    const token = tokens.find((t) => t.id === 'magalu-chat')?.token;

    if (!token) {
      setError('Token não encontrado');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://nerp.com.br/magalu/msg', {
        accessToken: token,
        filters: {
          status,
          startDate,
          endDate,
        },
      });

      console.log(response.data);

      let { results, meta } = response.data;

      // Ordena as mensagens da mais recente para a mais antiga
      results = results.sort((a, b) => 
        new Date(b.question.when_at) - new Date(a.question.when_at)
      );

      setConversations(results);
      setPagination(meta.page);
    } catch (err) {
      console.error('Erro ao carregar conversas:', err);
      setError('Erro ao carregar conversas.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newOffset) => {
    setPagination((prev) => ({ ...prev, offset: newOffset }));
  };

  return (
    <div className='py-32'>
      <h2>Conversations</h2>

      <div>
        <label>Status:</label>
        <select value={status} onChange={(e) => setStatus(e.target.value)}>
          <option value="">Todos</option>
          <option value="APPROVED">Aprovado</option>
          <option value="PENDING">Pendente</option>
        </select>

        <label>Data de Início:</label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />

        <label>Data de Fim:</label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />

        <button onClick={fetchConversations}>Filtrar</button>
      </div>

      {loading && <p>Carregando...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <ul>
        {conversations.map((conv) => (
          <li key={conv.id}>
            <strong>{conv.subject.extra.name}</strong>
            <p>{conv.question.message}</p>
            <p>
              <strong>Data:</strong>{' '}
              {new Date(conv.question.when_at).toLocaleString('pt-BR')}
            </p>
            <a href={conv.subject.extra.url} target="_blank" rel="noopener noreferrer">
              Ver Produto
            </a>
          </li>
        ))}
      </ul>

      <div>
        <button
          onClick={() => handlePageChange(pagination.offset - pagination.limit)}
          disabled={pagination.offset <= 0}
        >
          Anterior
        </button>

        <button
          onClick={() => handlePageChange(pagination.offset + pagination.limit)}
          disabled={pagination.offset + pagination.limit >= pagination.total}
        >
          Próximo
        </button>
      </div>
    </div>
  );
};

export default Conversations;

import React from 'react';
import { Grid, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const DateFilter = ({ startDate, setStartDate, endDate, setEndDate }) => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={3} >
            <Grid item xs={12} md={4}>
                <DatePicker
                    label="Data Inicial"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{ style: { color: 'white' } }}
                            inputProps={{ ...params.inputProps, style: { color: 'white' } }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <DatePicker
                    label="Data Final"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            InputLabelProps={{ style: { color: 'white' } }}
                            inputProps={{ ...params.inputProps, style: { color: 'white' } }}
                        />
                    )}
                />
            </Grid>
        </Grid>
    </LocalizationProvider>
);

export default DateFilter;
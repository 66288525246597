import React from 'react';

const BlingAuthButton = ({ id }) => {

  const scope = 'open:tickets-seller:read open:portfolio:read open:order-order:read open:order-delivery:read open:order-delivery:write open:order-logistics:read open:order-logistics:write open:order-invoice:read open:portfolio-skus-seller:read';
  const scope2 = 'services:questions-seller:read services:questions-seller:write services:conversations-seller:read services:conversations-seller:write';

  const handleAuth = () => {
    let authUrl = '';

    switch (id) {
      case 'bling':
        authUrl = 'https://nerp.com.br/bling/authorize';
        break;
      case 'mercado_livre':
        authUrl = `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=3019996311707366&redirect_uri=https://nerp.com.br/mlcallback`;
        break;
      case 'magalu':
        authUrl = `https://id.magalu.com/login?client_id=E0qQaDFjfPcghqOQcaWPC2XR0wQw0MJ4BNY0cKAvYHQ&redirect_uri=https://nerp.com.br/magalucallback&scope=${scope}&response_type=code&choose_tenants=true`;
        break;
      case 'magalu-chat':
        authUrl = `https://id.magalu.com/login?client_id=U6IVNfaPKZXskHR1uBXZ4imvpQjxlBwheUwvYRUSolQ&redirect_uri=https://nerp.com.br/magalumsg&scope=${scope2}&response_type=code&choose_tenants=true`;
        break;
      default:
        break;
    }

    window.location.href = authUrl;
  };

  return (
    <button onClick={handleAuth} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Conectar {id}
    </button>
  );
};

export default BlingAuthButton;
